import { render, staticRenderFns } from "./editDependent.vue?vue&type=template&id=0877ddee&scoped=true&"
import script from "./editDependent.vue?vue&type=script&lang=js&"
export * from "./editDependent.vue?vue&type=script&lang=js&"
import style0 from "./editDependent.vue?vue&type=style&index=0&id=0877ddee&prod&scoped=true&lang=css&"
import style1 from "./editDependent.vue?vue&type=style&index=1&id=0877ddee&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0877ddee",
  null
  
)

export default component.exports